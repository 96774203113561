@import '~antd/dist/antd.css';

.App {
   background: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 100%;
    user-select: none;
    transform: translate(-50%, -50%);
    /* &:hover {
      z-index: 1;
    } */
  }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.h1 {font-size: x-large;color:red;}


#pageTitle {
  font-size:24px;
  text-align: center;
  width:100%;
}
#legendTitle {
  font-size:14px;
  text-align: center;
  width:100%;
}

#searchTitle {
  font-size:14px;
  text-align: center;
  width:100%;
}


#mapHeader {
    color:black;
    max-height: 140px;
}

#mapTitle{
    font-family: arial, sans-serif;
    background: #6ab446;
    color:black;
    width:100%;
    padding:8px;
    font-size: calc(10px + 2vmin);
    font-weight: 400;
    height:7vh;
}

#mapFooter {
    font-family: Lato, sans-serif;
    background: black;
    color:white;
    height: 48px;
}

#termsButton {
  margin-top:4px;
  color: white;
  font-weight: medium;
  text-align:left;
  width: 100%;
}

#copyright{
  color: white;
  width: 100%;
  font-size:medium;
  text-align:left;
  margin-left:16px;
  margin-top:12px;
}

 #legend {
    font-family: Arial, sans-serif;
    background: #2298a6;
    color:black;
    padding: 10px;
    margin: 10px;
    border: 3px solid #000;
    text-align: left;
    width:33%;
    background-color:white;
  }


#charitableNum{
  margin-top: 12px;
  color: white;
  text-align:right;
  font-size:medium;
  margin-right:16px;
}

@media  ( min-width:1024px)
{

#mapHeader {
    color:black;
    max-height: 140px;
    background-color: transparent;
}
}

@media  ( min-width:1280px)
{
    #pageTitle {
      font-size:28px;
      margin: 0px 0px 8px 0px;
      text-align: center;
      font-weight: bold;
      width:100%;
    }

#legendTitle {
  font-size:20px;
  text-align: center;
  width:100%;
}

#searchTitle {
  font-size:24px;
  text-align: center;
  width:100%;
}

#mapHeader {
    color:black;
    max-height: 140px;
}

#mapTitle{
    font-family: arial, sans-serif;
    background: #D2E8C7;
    color:black;
    width:100%;
    padding:8px;
    font-size: calc(10px + 2vmin);
    font-weight: 400;
    height:7vh;
}

#mapFooter {
    font-family: Lato, sans-serif;
    background: black;
    color:white;
    height: 48px;
    align-content: center;
}

#termsButton {
  margin-left: 16px;
  margin: 4px;
  text-align:left;
  color: white;
  font-weight: medium;
}

#copyright{
  margin-top: 12px;
  margin-left:16px;
  color: white;
  width: 100%;
  text-align:left;
  font-size:medium;
}


 #legend {
    font-family: Arial, sans-serif;
    background: #2298a6;
    color:black;
    padding: 10px;
    margin: 10px;
    border: 3px solid #000;
    text-align: left;
    width:33%;
    background-color:white;
  }
}


.App-header {
  background-color:#2298a6;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

